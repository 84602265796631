import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
/* Auth Guard */
import { CanLoginActivate, CanAuthActivate } from "./common/TS-files/auth.guard";
/* Components */
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { MasterComponent } from './modules/master/master.component';
import { TrainingCoursesComponent } from "./modules/training-courses/training-courses.component";
import { PasswordResetComponent } from "./public/password-reset/password-reset.component";
import { ConfirmEmailComponent } from "./public/confirm-email/confirm-email.component";
/* plugin */
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
/* Shared */
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    // canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "password-reset",
    canActivate: [CanLoginActivate],
    component: PasswordResetComponent,
    pathMatch: "full",
  },
  {
    path: "verify-code",
    canActivate: [CanLoginActivate],
    component: ConfirmEmailComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      { path: 'master', component: MasterComponent, pathMatch: 'full' },
      {
        path: "dashboard",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (mod) => mod.DashboardModule
          )
      },
      {
        path: 'manage-subadmins',
        loadChildren: () => import('./modules/manage-subadmins/manage-subadmins.module')
          .then((m) => m.ManageSubadminsModule),
      },
      {
        path: "training-courses",
        canActivate: [CanAuthActivate],
        component: TrainingCoursesComponent,
      },
      {
        path: 'manage-roles',
        loadChildren: () => import('./modules/manage-roles/manage-roles.module')
          .then((m) => m.ManageRolesModule),
      },
      {
        path: 'manage-industries',
        loadChildren: () => import('./modules/industry/industry.module')
          .then((m) => m.IndustryModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./modules/analytics/analytics.module')
          .then((m) => m.AnalyticsModule),
      },
      {
        path: "user-management",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/user-management.module").then(
            (mod) => mod.UserManagementModule
          ),
      },
      {
        path: "products",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/products/products.module").then(
            (mod) => mod.ProductsModule
          ),
      },
      {
        path: "resources",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/resources/resources.module").then(
            (mod) => mod.ResourcesModule
          ),
      },
      {
        path: "news",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/news/news.module").then(
            (mod) => mod.NewsModule
          ),
      },
      {
        path: "orders",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/orders/orders.module").then(
            (mod) => mod.OrdersModule
          ),
      },
      {
        path: "reports",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/reports/reports.module").then(
            (mod) => mod.ReportsModule
          ),
      },
      {
        path: "contactUs-Inquiries",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/contact-us-inquiries/contact-us-inquiries.module").then(
            (mod) => mod.ContactUsInquiriesModule
          ),
      },
      {
        path: "training-course-requests",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/training-courses-request/training-courses-request.module").then(
            (mod) => mod.TrainingCoursesRequestModule
          ),
      },
      {
        path: "master",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/master-data/master-data.module").then(
            (mod) => mod.MasterDataModule
          ),
      },
      {
        path: "coupen-codes",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/coupen-codes/coupen-codes.module").then(
            (mod) => mod.CoupenCodesModule
          ),
      },
      {
        path: "cms",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/cms/cms.module").then(
            (mod) => mod.CmsModule
          ),
      },
      {
        path: 'email-template',
        // canActivate: [CanViewListActivate],
        data: { role: 'EmailTemplate' },
        loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
          .then((m) => m.EmailTemplatesModule),
      },
      {
        path: "push-notification",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/push-notification/push-notification.module").then(
            (mod) => mod.PushNotificationModule
          ),
      },
      {
        path: "manage-subscription",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/manage-subscription-plans/manage-subscription-plans.module").then(
            (mod) => mod.ManageSubscriptionPlansModule
          ),
      },
      {
        path: "payout",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/payout/payout.module").then(
            (mod) => mod.PayoutModule
          ),
      },
      {
        path: "settings",
        // canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/setting/setting.module").then(
            (mod) => mod.SettingModule
          ),
      },

      {
        path: "my-account",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/my-account/my-account.module").then(
            (mod) => mod.MyAccountModule
          ),
      },
      // {
      //   path: 'setting',
      //   loadChildren: () => import('./modules/setting/setting.module')
      //     .then(m => m.SettingModule)
      // },

      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

];

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    MainComponent,
    MasterComponent,
    TrainingCoursesComponent,
    PasswordResetComponent,
    ConfirmEmailComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [CanLoginActivate],
})
export class AppRoutingModule { }
