<style>
    .color-squre {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }

    .color-squre-mini {
        height: 10px;
        width: 10px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }

    .color-primary {
        position: relative;
    }

    .color-primary .color-squre {
        background: #5A4FC4;
    }

    .color-secound .color-squre {
        background: #F19A64;
    }

    .example-list {
        width: 500px;
        max-width: 100%;
        border: solid 1px #ccc;
        min-height: 60px;
        display: block;
        background: white;
        border-radius: 4px;
        overflow: hidden;
    }

    .example-box {
        padding: 20px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        font-size: 14px;
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-placeholder {
        opacity: 0;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .example-box:last-child {
        border: none;
    }

    .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .download-dropdown .download-dropdown-menu {
        width: 100%;
        color: #999999;
        top: 3rem !important;
        position: absolute;
        right: 0;
        z-index: 1000;
        float: left;
        min-width: 660px;
        margin: .125rem 0 0;
        font-size: 1rem;
        text-align: left;
        list-style: none;
        border: 1px solid rgba(0, 0, 0, 0.15);
        animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
        border-radius: 5px;
        box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
        background: #ffffff;
        display: none;
    }

    .left-col {
        flex: 0 0 20%;
        min-width: 175px;
        height: 350px;
        overflow-y: auto;
    }

    .left-col .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px 15px 20px;
        background: #ffffff;
    }

    .left-col .list-group-item .handle {
        margin-right: 10px;
    }

    .addIcon {
        outline: none;
    }

    .left-col .list-group-item .handle .custom-checkbox label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: #1A1A1A !important;
        font-weight: 600 !important;
    }

    .right-col .col-left {
        flex: 0 0 65%;
        margin-right: 1rem;
    }

    .right-col .col-right {
        flex: 0 0 auto;
    }

    .right-col .col-right .right-col-list {
        max-height: 200px;
        overflow: auto;
    }

    .template-list-outer {
        height: 220px;
        overflow-y: auto;
    }

    .template-list-outer .template-list .list-group-item {
        padding-top: 9px;
        padding-bottom: 9px;
        border: 0;
    }

    .download-dropdown.open .download-dropdown-menu {
        display: block;
    }

    .bottom-buttons button {
        min-width: 90px;
        color: #1A1A1A;
        border-color: rgba(153, 153, 153, 0.4);
        background: #ffffff;
    }

    .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px;
        background: #ffffff
    }

    .list-group-item label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: #1A1A1A !important;
    }

    .filter-form {
        position: relative;
    }

    .field-form {
        width: calc(100% - 56px);
    }

    .filter-form .row-add {
        position: absolute;
        right: 10px;
        bottom: 16px;
    }

    .save-filter {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .caption-image {
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-block;
        background-color: #CCCCCC;
    }

    .caption-image img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        top: 21px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
        position: absolute;
        padding-left: 30px;
        cursor: pointer;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before {
        top: 9px;
    }

    table.dataTable tbody tr {
        background: #ffffff;
    }

    table.dataTable.no-footer {
        border-bottom: none !important;
    }

    .error-block {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #FF5B5C;
    }

    @media screen and (max-width: 991px) {
        .btn .minW-md-0 {
            min-width: unset;
        }
    }
    /* table.dataTable thead th {
        padding: 1rem !important;
    }

    table.dataTable thead th:first-child {
        padding-left: 30px !important;
    }

    table.dataTable thead th:nth-child(2) {
        min-width: 100px;
    }

    table.dataTable tbody td {
        padding: .5rem 1rem !important;
    }

    table.dataTable tbody td {
        padding-left: 30px !important;
    } */
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
    <div class="animated fadeIn" [ngClass]="type !== 'userOperatorlist' ? 'card' : ''">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->
<div class="content-wrapper datatable-main" [hidden]="showSkeletonLoader1">
    <div class="animated fadeIn" [ngClass]="type !== 'userOperatorlist' ? 'card' : ''">
        <!-- TABLE HEADER START-->
        <div class="card-body">
            <!-- Data Table Start -->
            <!-- {{tableConfig.cols | json}} -->

            <!-- filter/download/search buttons -->
            <div class="user-listing-filterOptions" *ngIf="this.conditions.showTableHeader">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="top-filter-btn" *ngIf="this.conditions.showFilter">
                            <button (click)="openFilter()" class="btn btn-default-filter" type="button">
                                <span class="d-none d-sm-none d-lg-inline-block">Filter</span>
                            </button>
                        </div>
                        <!-- apply active , inactive and delete on mutiple users -->
                        <div class="d-inline-block"
                            *ngIf="this.conditions.showApplyStatus && this.selectedUserList.length">
                            <ng-container>
                                <form #statusForm="ngForm" (ngSubmit)="submitStatusForm(statusForm,statusFormData)"
                                    class="w-150">
                                    <ng-select class="sm-ng-select ng-select-line select-option" [items]="statusList"
                                        bindLabel="statusFormData?.status" bindValue="statusFormData.status"
                                        placeholder="Select" [clearable]='false' [searchable]='false'
                                        [dropdownPosition]="'auto'" #status="ngModel" name="status"
                                        [(ngModel)]="statusFormData.status" required
                                        (change)="submitStatusForm(statusForm,statusFormData)">
                                    </ng-select>
                                </form>
                                <!-- apply active , inactive and delete on mutiple users -->
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngIf="this.conditions.search">
                            <div class="search-input-wrapper">
                                <form>
                                    <div class="form-group mb-0">
                                        <em class="bx bx-search"></em>
                                        <input type="text" class="form-control icon-search" placeholder="Search..."
                                            name="search" #search="ngModel" [(ngModel)]="searchText" autocomplete="off"
                                            (keyup.enter)="searchData($event)">
                                        <div class="remove-icon"><em class="bx bx-x" *ngIf="searchText"
                                                (click)="removeSearch()"></em></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="this.conditions.showButton && !conditions.modal">
                            <button type="button" class="nav-link  btn btn-primary listlink"
                                [routerLink]="[this.conditions.showButton.routerLink]">
                                <em class="fa fa-plus" aria-hidden="true"></em>
                                <span>{{this.conditions.showButton.buttonName}}</span>
                            </button>
                        </ng-container> -->
                    </div>
                </div>
                <div class="filter-data-dropdown" *ngIf="this.conditions.showFilter" [ngClass]="status ? 'open' : ''">
                    <div class="custom-dropdown-menu">
                        <div class="row">
                            <ng-container *ngFor="let i of tableConfig?.cols">
                                <div class="col-lg-4 col-sm-6 col-12" *ngIf="i.filter">
                                    <div class="form-group">
                                        <label>{{i.colName}}</label>
                                        <ng-select *ngIf="i.colName == 'Status'" class="ng-select-line"
                                            [items]="statusFilterlist" bindValue="value" bindLabel="status"
                                            placeholder="Select {{i.colName}}" [clearable]='false'
                                            [multiple]="i.colName == 'Status' ? false : true"
                                            [dropdownPosition]="'auto'" [closeOnSelect]="true"
                                            (focus)="getSatutsFilterlistData(i.colFieldname)" [(ngModel)]="i.value"
                                            (keydown)="i.colName == 'E-mail' || i.colName == 'Buyer Email' || i.colName == 'Order ID' || i.colName == 'Return ID' || i.colName == 'Shipment ID' || i.colName == 'Transaction ID'  ? '' : (i.colName == 'Mobile' ? restrictKeyPressService.AllowNumbers($event) : restrictKeyPressService.AllowChar($event))"
                                            required>
                                        </ng-select>

                                        <input type="text" [name]="i.colFieldname" class="form-control"
                                            [id]="i.colFieldname" *ngIf="i.isDateRange" autocomplete="off"
                                            bsDaterangepicker [(ngModel)]="i.value"
                                            [bsConfig]="{ rangeInputFormat : 'YYYY-MM-DD',dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false, containerClass: 'theme-green' }"
                                            placeholder="Select Dates"
                                            (bsValueChange)="dateCreated(i.colFieldname,$event)" />
                                        <input type="text" [name]="i.colFieldname" class="form-control"
                                            [id]="i.colFieldname" *ngIf="i.isDate || i.isDateTime" autocomplete="off"
                                            bsDatepicker [(ngModel)]="i.value"
                                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false, containerClass: 'theme-green' }"
                                            placeholder="Select Date" />

                                        <ng-container *ngIf="i.colName == 'Discount'">
                                            <div class="d-flex">
                                                <input type="text" name="min" class="form-control" id="min"
                                                    autocomplete="off" placeholder="Minimum" />
                                                <input type="text" name="max" class="form-control ml-3" id="max"
                                                    autocomplete="off" placeholder="Maximum" />
                                            </div>
                                        </ng-container>

                                        <input class="form-control" autocomplete="off"
                                            [hidden]="i.colName === 'Status' || i.isDate || i.isDateTime"
                                            placeholder="Enter {{i.colName}}" [(ngModel)]="i.value">
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <hr>
                        <div class="row filter-btn">
                            <div class="col-md-12 text-right">
                                <!-- [disabled]="disableApplyFilter" -->
                                <button type="button" class="btn btn-primary" (click)="applyFilter()">Apply
                                    Filter</button>
                                <button type="button" class="btn btn-outline btn-primary" (click)="resetFilter()"
                                    [disabled]="disableResetFilter">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="datatable-inner">
                <!-- column hide show-->
                <ng-container *ngIf="this.conditions.showColumnHide">
                    <button type="button" class="settings-button" data-toggle="collapse"
                        data-target="#columnVisiblityFilter" aria-expanded="false" aria-controls="columnVisiblityFilter"
                        [attr.aria-expanded]="!columnVisibilityOptions" (click)="tooglecolumnVisibilityFilter()"
                        tooltip="Column Options" placement="bottom">
                        <em class='bx bx-cog'></em>
                    </button>

                    <!-- ON COLLAPSE COLUMN HIDE SHOW -->
                    <div class="collapse custom-options" [collapse]="!columnVisibilityOptions" [isAnimated]="true"
                        id="columnVisiblityFilter">
                        <div class="cp-row" *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'':''">
                            <ng-container *ngIf="i.columnVisibility">
                                <div class="custom-checkbox sm">
                                    <label>
                                        <input type="checkbox" name="status" [(ngModel)]="i.isVisible"
                                            (change)="changeCheckBoxValue()" [disabled]="i.disable">
                                        <span></span>
                                        {{i.colName}}
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <hr />
                <div class="row text-right filter-btn">
                    <div class="col-md-12"> 
                        <button type="button" class="btn btn-primary" (click)="resetColumnVisible()">Reset</button>
                        <button type="submit" class="btn btn-primary"
                            (click)=" CallAPIchangeColumnVisibility(tableConfig?.cols)">Save</button>
                        <button type="button" class="btn btn-primary" (click)="selectAllColumnVisible()">Select
                            All</button>                            
                    </div>
                </div> -->
                    </div>
                    <!-- ON COLLAPSE COLUMN HIDE SHOW -->

                </ng-container>
                <!-- column hide show-->
                <div class="table-responsive">
                    <!-- datatable -->
                    <table [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table (sorted)="onSorted($event)"
                        class="custom-table table table-striped" id="table" *ngIf="tableData?.data"
                        aria-describedby="table" width="100%">
                        <thead>
                            <tr>
                                <th [hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort"
                                    *ngFor="let i of tableConfig?.cols" id="th1"
                                    [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
                                    <ng-container
                                        *ngIf="i.type!=='multipleSelection' && i.type!=='switch' && i.type !='columnSettings'">
                                        {{i.colName}}
                                    </ng-container>
                                    <ng-container *ngIf="i.type =='columnSettings' ">

                                        <div class=" btn-group" dropdown [insideClick]="true">
                                            <em dropdownToggle class=" fa fa-cog">
                                            </em>
                                            <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                <div class="card">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="row" cdkDropList class="example-list"
                                                                (cdkDropListDropped)="drop($event)">
                                                                <div *ngFor="let setting of colSettingCols"
                                                                    class="example-box" cdkDrag
                                                                    [ngClass]="(setting.columnVisibility)?'col-md-12':''">
                                                                    <ng-container *ngIf="setting.drag">
                                                                        <div class="custom-checkbox">
                                                                            <label>
                                                                                <input type="checkbox" name="status"
                                                                                    [(ngModel)]="setting.isVisible"
                                                                                    [disabled]="setting.disable">
                                                                                <span></span>
                                                                                {{setting.colName}}
                                                                            </label>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <!-- <div class="text-right filter-btn">
                                                                    <div class="col-md-12">
                                                                        <button type="submit" class="btn btn-primary"
                                                                            (click)="saveColumnSettings(colSettingCols)">Save</button>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="i.type=='switch'">{{i.colName}}</ng-container>
                                    <div *ngIf="i.type=='multipleSelection'" class="custom-checkbox">

                                        <label>
                                            <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                (change)="selectAllUser(i)">
                                            <span></span>
                                        </label>
                                    </div>

                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <ng-container *ngIf="tableData.data.length">
                                <tr class="animated fadeIn" *ngFor="let i of tableData?.data;let y=index;">
                                    <!-- <td></td> -->
                                    <td [ngClass]="j.type=='action'?'d-flex action-td':''" [hidden]="!j.isVisible"
                                        *ngFor="let j of tableConfig?.cols">
                                        <div *ngIf="j.type=='multipleSelection'" class="custom-checkbox">
                                            <ng-container *ngIf="type === 'promoCodeList';else basicMultipleSelection">
                                                <label *ngIf="i['usedInOrders'].length == 0">
                                                    <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                        (change)="selectMultipleUser(i)"><span></span>
                                                </label>
                                            </ng-container>
                                            <ng-template #basicMultipleSelection>
                                                <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                        (change)="selectMultipleUser(i)"><span></span></label>
                                            </ng-template>
                                        </div>
                                        <!-- <div *ngIf="j.type=='switch'" class="custom-control custom-switch light" (click)="changeStatus(i)">
                                            <input type="checkbox" id="id" class="custom-control-input" [ngModel]="i[j.colFieldname]" (click)="$event.stopPropagation()">
                                            <label for="id" class="custom-control-label"></label>
                                        </div> -->
                                        <div *ngIf="j.type=='switch'">
                                            <label class="switch ">
                                                <input type="checkbox" class="success" [(ngModel)]="i[j.colFieldname]"
                                                    (click)="changeStatus(i)">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div *ngIf="j.type=='image'" class="thumb-img">
                                            <span class="caption-image">
                                                <img [src]="i[j.colFieldname] ?  uploadImageUrl + i[j.colFieldname] : 'assets/images/NoProfile.png'"
                                                    alt="no img">
                                            </span>

                                        </div>
                                        <ng-container *ngIf="j.type=='action'">

                                            <div class="btn-group" dropdown container="body">
                                                <a href="javascript:void(0)" id="action-basic" dropdownToggle
                                                    type="button" class="action-button" aria-controls="dropdown-action">
                                                    <em class='bx bx-dots-vertical-rounded'></em>
                                                </a>
                                                <ul id="dropdown-action" *dropdownMenu
                                                    class="dropdown-menu action-dropdown" role="menu"
                                                    aria-labelledby="action-basic">
                                                    <!-- <li role="menuitem">
                                                        <a class="dropdown-item" href="javascript:void(0)"></a>
                                                    </li> -->
                                                    <li *ngFor="let k of tableConfig?.actions"
                                                        class="action-icon menuitem">
                                                        <a class="dropdown-item" href="javascript:void(0)"
                                                            (click)="performAction(k,i)">
                                                            <ng-container *ngIf="k.type === 'icon'">
                                                                <em [class]="k.class" aria-hidden="true"
                                                                    [tooltip]="k.tooltip"></em>
                                                                {{k.tooltip}}
                                                            </ng-container>
                                                            <div *ngIf="k.type === 'button'">
                                                                <ng-container>
                                                                    <button type="button" [class]="k.class"
                                                                        (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                                                                </ng-container>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="j.type =='symbol'" class="align-center">
                                            <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                                            <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                                        </div>
                                        <div *ngIf="j.type == 'addIcon'" class="addIcon">
                                            <span class='bx bxs-plus-circle'></span>
                                        </div>
                                        <div *ngIf="j.type === 'button' ">
                                            <ng-select class="sm-ng-select dt-status-dropdown" [items]="statusFilterlist"
                                                [(ngModel)]="i[j.colFieldname]" [clearable]='false'
                                                [dropdownPosition]="'auto'" [closeOnSelect]="true"
                                                (focus)="getSatutsFilterlistData(i.colFieldname)"
                                                (change)="changeStatusFromList($event, i)">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="btn"
                                                        [ngClass]="item.value == 'Pending' ? 'btn-warning' : (item.value == 'Completed' ? 'btn-secondary' :(item.value == 'Inprogress' ? 'btn-primary' : ''))">{{item.title}}</span>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                                    <span class="btn"
                                                        [ngClass]="item.value == 'Pending' ? 'btn-warning' : (item.value == 'Completed' ? 'btn-secondary' :'btn-primary')">{{item.title}}</span>
                                                </ng-template>
                                            </ng-select>
                                        </div>

                                        <div *ngIf="j.type=='text'">
                                            <button *ngIf="i[j.colFieldname] == 'Pending'"
                                                class="btn-group btn btn-primary"
                                                disabled="disabled">{{i[j.colFieldname].toUpperCase()}}</button>
                                            <div
                                                [ngClass]="{'name-with-image': j.colName == 'Name', 'table-course': j.colName == 'Course Name'}">
                                                <ng-container *ngIf="j.coursePic" class="thumb-img">
                                                    <span class="tcourse-image">
                                                        <img [src]="i[j.coursePic] ? i[j.coursePic] : 'assets/images/NoProfile.png'"
                                                            alt="no img">
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="j.colName === 'Name'">
                                                    <div class="short-name">{{ i[j.colFieldname].charAt(0) }}</div>
                                                </ng-container>
                                                <div
                                                    [ngClass]="(j.colName == 'Name' || j.colName == 'Course Name') ? 'column-name' : ''">
                                                    {{j.isObj ?
                                                    i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] | date:
                                                    (j.isDateTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd') ) :
                                                    i[j.colFieldname] ? i[j.colFieldname] :
                                                    '--')}}</div>
                                            </div>
                                        </div>
                                        <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname]"></div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="!tableData.data.length">
                                <td class="text-center w-100 p-2" [attr.colspan]="tableConfig.cols.length">
                                    No data available.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <!-- Data Table End -->
            </div>
        </div>
    </div>
    <!-- TABLE FOOTER START-->
    <div class="card-footer" *ngIf="this.conditions.showTableFooter">
        <div class="custom-pagination d-flex align-items-center justify-content-between" *ngIf="tableData.data?.length">
            <div class="pagination-left">
                <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)" [items]="itemsPerPageList"
                    placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false' [searchable]='false'
                    [dropdownPosition]="'auto'" [labelForId]="tableData.type">
                </ng-select>
                <span class="record-count ml-3">per page</span>
            </div>
            <div class="pagination-list">
                <pagination [itemsPerPage]='itemsPerPage' #customPagination [totalItems]="total"
                    (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev" nextText="Next"
                    firstText="&laquo;" lastText="&raquo;">
                </pagination>
            </div>
        </div>
        <!-- Pagination End -->
    </div>
    <!-- TABLE FOOTER END-->
</div>