import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
/* plugins */
import swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { NgxSpinnerService } from 'ngx-spinner';
/* constants */
import { URLConstants } from '../constants/routerLink-constants';
/* environment */
import { environment } from 'src/environments/environment';
/* service */
import { LocalStorageService } from './local-storage.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authorised: any = false;
  public API_URL = '';
  public swal = swal;
  public platformId: any;

  constructor(public router: Router, public injector: Injector, public Http: HttpClient, public localStorageService: LocalStorageService, private spinnerService: NgxSpinnerService,) {
    this.API_URL = environment.apiUrl;
    this.platformId = injector.get(PLATFORM_ID);
  }

  /*******************************************************************************************
  @PURPOSE      	: Call api.
  @Parameters 	  : {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                    }
  @RETURN         : Data obtain for API
  /*****************************************************************************************/
  public showSkeletonLoader: boolean;
  callApi(url, data, method, isPublic?, isForm?, html?, isExport?): Promise<any> {
    this.showSkeletonLoader = true;
    this.spinnerService.show();
    let headers;
    if (isPublic) {
      headers = new HttpHeaders({ 'content-Type': 'application/json' });
    } else if (isExport) {
      headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': `Bearer ${this.localStorageService.getToken('accessToken')}` });
    } else if (html) {
      headers = new HttpHeaders({ 'content-Type': 'text/html', 'Authorization': `Bearer ${this.localStorageService.getToken('accessToken')}` });
    } else {
      headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': `Bearer ${this.localStorageService.getToken('accessToken')}` });
    }
    if (isForm) {
      headers = new HttpHeaders({ Authorization: `Bearer ${this.localStorageService.getToken('accessToken')}` });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this.Http.post(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
          this.spinnerService.hide();
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      } else if (method === 'get') {
        this.Http.get(this.API_URL + url, { headers, params: data }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
          this.spinnerService.hide();
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      } else if (method === 'put') {
        this.Http.put(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
          this.spinnerService.hide();
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      } else if (method === 'delete') {
        this.Http.delete(this.API_URL + url, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
          this.spinnerService.hide();
        }, (error) => {
          this.spinnerService.hide();
          resolve(error.error);
          this.error(error);
        });
      }
    });

  }

  callApiObservable(url, data) {
    const headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': `Bearer ${this.localStorageService.getToken('accessToken')}` });
    return this.Http.post(this.API_URL + url, data, { headers }).pipe(map((rsp) => {
      return rsp
    }));
  }

  /*****************************************************************************************
 @PURPOSE      : To Show session LogOut popup
 @PARAMETERS   : NA
 @RETURN       : NA
 /*****************************************************************************************/
  sessionLogOut() {
    window.localStorage.clear();
    swal({
      position: 'center',
      type: 'error',
      text: 'Session Timeout',
      showConfirmButton: false,
      timer: 1800,
      customClass: 'custom-toaster',
    });
    this.router.navigate([URLConstants.LOGIN]);
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show error on status 401, 422 or any other error
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  error(error) {
    if (error.status === 401 || error.message == 'Invalid token.') {
      this.sessionLogOut();
    } else if (error.status === 500) {
      swal({
        position: 'center',
        type: 'error',
        text: 'Internal Server Error',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
  }
  /*****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : Used for encrypt the value
  @PARAMETERS   : data
  @RETURN       : Encrypted Data
  /*****************************************************************************************/
  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123');
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : Used for decryption of encrypted code
  @PARAMETERS   : data
  @RETURN       : Decrypted Data
  /*****************************************************************************************/
  decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(data, 'secret key 123').toString(CryptoJS.enc.Utf8));
  }
  /****************************************************************************************/

  /*************************************************************
  @PURPOSE  : To check server or browser
  /*************************************************************/
  isBrowser() {
    if (isPlatformBrowser(this.platformId)) {
      return true;
    } else {
      return false;
    }
  }
  /****************************************************************************************/

}
