import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
// routing
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// Common
import { CommonService } from './common/service/common.service';
import { CanAuthActivate, CanLoginActivate } from './common/TS-files/auth.guard';
import { ErrorMessages } from './common/TS-files/errorMessages';


// plugins
import { ModalModule,BsModalRef } from 'ngx-bootstrap/modal';

// component
import { AppComponent } from './app.component';


@NgModule({
  bootstrap: [AppComponent],

  declarations: [
    AppComponent
  ],

  imports: [
    SharedModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
  ],

  exports: [],
  providers: [
    CanLoginActivate,
    CommonService,
    CanAuthActivate,
    BsModalRef,
    // BsDropdownDirective,
    ErrorMessages
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }


