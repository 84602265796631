<div class="main-content-area">
    <div class="main-content-block">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="content-header-title">Master</li>
                <li class="breadcrumb-item">
                    <a href="dashboard.html"><i class="bx bx-home-alt"></i></a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Manage Master
                </li>
            </ol>
        </nav>

        <!-- <div class="d-flex align-items-center justify-content-between mb-2 flex-wrap">
            <div class="d-flex align-items-center">
                <div class="search position-relative has-icon-left">
                    <input type="search" class="form-control text-capitalize" placeholder="search">
                    <div class="form-control-position">
                        <em class="bx bx-search"></em>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary glow-primary" data-toggle="modal" data-target="#countryModal">
                Add
            </button>
        </div> -->

        <!-- <div class="card notification-card tabbing p-0">
            <div class="custom-tabs">
                <tabset id="content" class="tab-content">
                    <tab heading="Country" id="tab1" (selectTab)="onSelect($event)">
                        <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                        </app-responsive-data-table>
                    </tab>
                    <tab heading="Timezone">
                        <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                        </app-responsive-data-table>
                    </tab>
                    <tab heading="Currency">
                        <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                        </app-responsive-data-table>
                    </tab>
                </tabset>
            </div>
        </div> -->
        <div>
            <div class="animated fadeInUp box-wrapper notification-card card tabbing p-0">
                <div class="m-portlet  settings-wrapper approve-template custom-tabs">
                    <tabset class="tab-links tab-content" id="content">
                        <tab id="tab1" (selectTab)="onSelect('country')">
                            <ng-template tabHeading>
                                <i class="bx bx-globe align-middle mr-1"></i><span class="align-middle">Country</span>
                            </ng-template>
                            <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.country">
                            </app-responsive-data-table>
                        </tab>

                        <tab [active]="tabs.timezone" (selectTab)="onSelect('timezone')" customClass="date-icon common-icon">
                            <ng-template tabHeading (selectTab)="onSelect('timezone')">
                                <i class="bx bx-time-five align-middle mr-1"></i><span class="align-middle">Timezone</span>
                            </ng-template>
                            <app-responsive-data-table [tableSetupData]="tableSetupData" *ngIf="tabs.timezone">
                            </app-responsive-data-table>
                        </tab>

                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>