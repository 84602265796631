<div class="main-content-area">
    <header id="header" class="header-section fixed-top">
        <nav class="navbar d-flex navbar-expand">
            <div class="brand-logo">
                <a href="javascript:;">
                    <img src="assets/images/logo.svg" alt="Logo" width="157" height="33">
                </a>
            </div>
            <ul class="navbar-nav flex-row ml-auto d-flex">
                <li class="nav-item nav-search">
                    <a class="nav-link nav-link-search" href="javascript:;" (click)="openSearch()">
                        <em class="bx bx-search"></em>
                    </a>
                    <div class="search-input">
                        <div class="search-box">
                            <div class="search-input-icon"><em class="bx bx-search primary"></em></div>
                            <input class="input" type="text" placeholder="Search..." tabindex="-1"
                                data-search="template-search">
                            <div class="search-input-close" (click)="closeSearch()"><em class="bx bx-x"></em></div>
                        </div>
                        <ul class="search-list">
                            <li>
                                <a>
                                    <span class="bx bx-error-circle"></span>
                                    <span>No results found.</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item dropdown dropdown-notification" dropdown>
                    <a class="nav-link nav-link-label" dropdownToggle type="button" id="notificationDropdown"
                        aria-controls="dropdown-animated">
                        <em class="bx bx-bell bx-tada bx-flip-horizontal"></em>
                        <span class="badge badge-pill badge-primary badge-up">5</span>
                    </a>
                    <div class="dropdown-menu dropdown-notification-box dropdown-menu-right" id="notificationDropdown"
                        *dropdownMenu role="menu" aria-labelledby="button-animated">
                        <div class="notification-section ps ps--active-y">
                            <div class="notification-row">
                                <div class="notification-header">
                                    <h6>Today</h6>
                                    <a href="javascript:;">Mark as Read</a>
                                </div>
                                <div class="notification-item">
                                    <div class="icon-box">
                                        <em class="bx bx-x-circle"></em>
                                    </div>
                                    <div class="notification-info">
                                        <h6>Elise Beverley fails quiz 3 times</h6>
                                        <p>Yesterday 17:55</p>
                                    </div>
                                </div>
                                <div class="notification-item">
                                    <div class="icon-box">
                                        <em class="bx bx-briefcase-alt"></em>
                                    </div>
                                    <div class="notification-info">
                                        <h6>Elise Beverley uploaded a job</h6>
                                        <p>29 Dec 11:30</p>
                                    </div>
                                </div>
                                <div class="notification-item">
                                    <div class="icon-box">
                                        <em class="bx bx-image-alt"></em>
                                    </div>
                                    <div class="notification-info">
                                        <h6>Elise Beverley uploads all photos in photo upload uploader</h6>
                                        <p>29 Dec 11:30</p>
                                    </div>
                                </div>
                            </div>
                            <div class="notification-row">
                                <div class="notification-header">
                                    <h6>Previous</h6>
                                </div>
                                <div class="notification-item read-notification">
                                    <div class="icon-box">
                                        <em class="bx bx-key"></em>
                                    </div>
                                    <div class="notification-info">
                                        <h6>Your password is successfully changed</h6>
                                        <p>22 Dec 13:13</p>
                                    </div>
                                </div>
                                <div class="notification-item">
                                    <div class="icon-box">
                                        <em class="bx bx-star"></em>
                                    </div>
                                    <div class="notification-info">
                                        <h6>Elise Beverley submitted course feedback</h6>
                                        <p>29 Dec 11:30</p>
                                    </div>
                                </div>
                                <div class="notification-item">
                                    <div class="icon-box">
                                        <em class="bx bx-calendar-x"></em>
                                    </div>
                                    <div class="notification-info">
                                        <h6>CSP & Inside/ Out Cable will expire in 3 days</h6>
                                        <p>29 Dec 11:30</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="view-btn">
                            <a href="javascript:;">View All</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item user-dropdown dropdown" dropdown>
                    <a class="nav-link dropdown-toggle dropdown-user-link pr-0" dropdownToggle type="button"
                        id="userDropdown" aria-controls="dropdown-animated">
                        <span class="profile-header"><img class="" [src]="photo ? photo : 'assets/images/NoProfile.png'"
                                alt="no img"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" id="userDropdown" *dropdownMenu role="menu"
                        aria-labelledby="button-animated">
                        <a class="dropdown-item" [routerLink]="[URLConstants.MY_ACCOUNT]"><em
                                class='bx bx-user mr-2'></em>My Account</a>
                        <a class="dropdown-item" (click)="logout()"><em class='bx bx-log-out mr-2'></em>Logout</a>
                    </div>
                </li>
            </ul>
        </nav>
    </header>
</div>