export const callAPIConstants = {
    AdminLogin: 'login',
    AdminRegister: 'admin/register',
    VerifyOTP: 'verifyEmailToken',
    ForgotPassword: 'forgotPassword',
    ResetPassword: 'resetPassword',
    AdminLogout: 'logout',

    // Account
    GetAccountDetail: 'myAccount',
    UpdateAccountDetails: 'myAccount',
    ChangePassword: 'changePassword',

    AdminGetProfile: 'admin/profile',
    AdminEditProfile: 'admin/editProfile',
    AdminFileUpload: 'admin/fileUpload',
    AdminChangePassword: 'admin/changePassword',
    UpdateTheme: 'admin/updateThemeDetails',
    CountriesListing: 'countriesListing',
    getCountryDetails: 'getCountryDetails',
    getCurrencyDetails: 'getCurrencyDetails',
    getTimezoneDetails: 'getTimezoneDetails',
    getCountriesList: 'getCountriesList',
    addUpdateCountry: 'addUpdateCountry',
    addUpdateCurrency: 'addUpdateCurrency',
    addUpdateTimezone: 'addUpdateTimezone',
    changeCountriesStatus: 'changeCountriesStatus',
    deleteCountries: 'deleteCountries',
    timezoneListing: 'timezoneListing',
    changeTimezoneStatus: 'changeTimezoneStatus',
    deleteTimezones: 'deleteTimezones',
    getTimezoneList: 'getTimezoneList',
    getCurrenciesList: 'getCurrenciesList',
    currenciesListing: 'currenciesListing',
    deleteCurrencies: 'deleteCurrencies',
    changeCurrenciesStatus: 'changeCurrenciesStatus',
    //social media sdk
    getsocialMediaSDK: 'getSocialMediaSDK',
    addSocialMediaSDK: 'addSocialMediaSDK',
    //social media link
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',
    //global setiing 
    addGlobalSettings: 'addGlobalSettings',
    getGlobalSettings: 'getGlobalSettings',
    //payment setting
    getPaymentDetails: "getPaymentDetails",
    addPaymentDetails: "addPaymentDetails",
    //smtp setting
    addSMTPAndSMSSettings: "addSMTPAndSMSSettings",
    getSMTPAndSMSSettings: "getSMTPAndSMSSettings",
    //email notification
    getEmailNotificationSettings: "getEmailNotificationSettings",
    addEmailNotificationSettings: "addEmailNotificationSettings",

    // email-template
    EmailTemplateList: 'emailTemplates/getEmailTemplateList',
    AddEmailTemplate: 'emailTemplates',
    EmailTemplateDetail: 'emailTemplates/',
    UpdateEmailTemplate: 'emailTemplates/',
    EmailColumnData: 'getEmailColumnValues',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail',

    //Faq
    FaqsListing: 'faqsListing',
    DeleteFaqs: 'deleteFAQs',
    FaqsDetails: 'getFAQDetails/',
    FaqsAddorUpdate: 'addUpdateFAQ',
    FaqsCategoryListing: 'faqCategoriesListing',
    FaqsStatusChange: 'changeFAQsStatus',
    FaqsSearchList: 'getFAQsList',
    AddUpdateFaqsCategory: 'addUpdateFAQCategory',
    DeleteFaqsCategory: 'deleteFAQCategories',
    FaqsCategoryDetails: 'getFAQCategoryDetails/',
    FaqsCategoryStatus: 'changeFAQCategoriesStatus',
    FaqsCategoriesSearchList: 'getFAQCategoriesList',


    // static && Blogs
    Listing: 'listBlog',
    DeleteBlog: 'deleteBlog',
    ChangeStatus: 'changeBlogStatus',
    GetBlogDetails: 'getBlogDetails/',
    AddBlog: 'addUpdateBlog',
    BlogCategoryListing: 'listBlogCategory',

    AddUpdateBlogCategory: 'addUpdateblogCategory',
    GetBlogCategoryDetails: 'getBlogCategoryDetails/',
    BlogCategoryStatusChange: 'changeBlogCategoryStatus',
    DeleteBlogCategory: 'deleteBlogCategory',

    // Role APIs
    RolesList: 'roles/getRoleList',
    AddRole: 'roles',
    GetRoleDetail: 'roles/',
    DeleteRole: 'roles/delete',
    RoleChangeStatus: 'roles/changeStatus',
    RoleGetColumnData: 'roles/roleFieldsList',
    RoleGetModuleData: 'roles/roleModuleList',
    RoleGetaddedByColumnData: 'adminListInRoles',

    // Team Member
    AddTeamMember: 'teamMembers',
    GetTeamMember: 'teamMembers/',
    TeamMembers: 'teamMembers/getListing',
    TeamChangeStatus: 'teamMembers/changeStatus',
    TeamResendPassword: 'teamMembers/resendPassword/',

    // Users & Operators & Content Prividers
    UserList: 'users/getUsersList',
    UserChangeStatus: 'users/changeStatus',
    GetUserDetail: 'users/getUserProfile/',
    UserOperatorList: 'users/getUserOperatorList',
    OperatorList: 'operators/getListing',
    GetOperatorById: 'operators/',
    AddOperator: 'operators',
    OperatorChangeStatus: 'operators/changeStatus',
    ResendPassword: 'resendPassword/',

    // Training Course APIs
    GetCourseList: 'trainingCourse/getListing',
    GetCourseById: 'trainingCourse/',
    FileUpload: 'uploadImage',
    ChangeCourseStatus: 'trainingCourse/changeStatus',
    GetRequestedCourses: '',
    ChangeRequestedCourseStatus: '',

    // Training Course REquests APIs
    GetCourseRequests: 'trainingCourse/getCourseRequests',
    ChangeCourseRequestStatus: 'trainingCourse/changeRequestStatus'

};

