<!-- BEGIN: Sub Header  -->
<app-sub-header [subHeaderData]="subHeaderData"></app-sub-header>
<!-- END: Sub Header  -->
<div class="main-content-area">
    <div class="main-content-block">
        <div class="page-title">
            <h1>Morderate Training Course</h1>
            <div class="page-button">
                <button class="btn btn-outline btn-secondary"
                    (click)="responsiveDataTable.exportTemplate()">Export</button>
            </div>
        </div>

        <!-- filterbar buttons -->

        <!-- filter bar buttons end -->
        <div class="m-content animated fadeIn">
            <!-- BEGIN: Responsive Data Table  -->
            <app-responsive-data-table [tableSetupData]="tableSetupData" #responsiveDataTable>
            </app-responsive-data-table>
            <!-- END: Responsive Data Table  -->
        </div>
    </div>
</div>