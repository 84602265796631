import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { callAPIConstants } from '../constants/callAPI-constants';
import { URLConstants } from '../constants/routerLink-constants';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service';


@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	URLConstants = URLConstants;
	callAPIConstants = callAPIConstants;

	constructor(public localstorage: LocalStorageService, private router: Router, private commonService: CommonService,
		private showErrorService: ShowErrorService) { }

	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout() {
		let Id;
		let Pass;
		let remember;
		if (this.localstorage.getToken('Id') && this.localstorage.getToken('Pass')) {
			Id = this.localstorage.getToken('Id');
			Pass = this.localstorage.getToken('Pass');
			remember = true;
		}
		this.commonService.callApi(this.callAPIConstants.AdminLogout, '', 'post', false, false, false).then((success) => {
			if (!success.errors) {
				this.localstorage.clearToken();
				this.showErrorService.popToast('success', success.extra_meta.message);
				this.router.navigate([this.URLConstants.LOGIN]);
			} else {
				this.showErrorService.popToast('error', success.errors.message);
			}
			if (remember) {
				this.localstorage.setToken('Id', Id);
				this.localstorage.setToken('Pass', Pass);
			}
		})

	}
	/*************************************************************/
}
