<section class="login-wrapper" style="background-image:url(assets/images/login-bg.jpg);">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo.svg" alt="Logo">
                </a>
            </div>
            <div class="login-content">
                <h1>Reset Password</h1>
                <p>Enter new password for your SkillsBase account</p>
            </div>
            <div class="login-form">
                <form (ngSubmit)="resetPassword(resetPasswordForm, user)" #resetPasswordForm="ngForm">
                    <div class="form-group">
                        <label for="newpassword">New password</label>
                        <input [type]="passwordType ? 'text' : 'password'"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$" name="password"
                            id="newpassword" placeholder="Enter New password" class="form-control"
                            [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''" required
                            [(ngModel)]="user.password" #password="ngModel">
                        <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field"
                            class="bx bx-hide field-icon toggle-password" [ngClass]="{
                            'bx-bx-hide': !passwordType,
                            'bx-show': passwordType
                          }" (click)="toggleFieldTextType()"></span>
                        <div *ngIf="(submitted || password.touched) && password.invalid" class="has-error">
                            {{showErrorService.showError('newPassword', password.errors)}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="confirmpassword">Confirm password</label>
                        <input [type]="passwordType1 ? 'text' : 'password'" name="confirmPassword" id="confirmPassword"
                            placeholder="Enter Confirm password" class="form-control"
                            [ngClass]="((submitted || confirmPassword.touched) && confirmPassword.invalid)?'has-danger':''"
                            required [(ngModel)]="user.confirmPassword" #confirmPassword="ngModel">
                        <span [tooltip]="passwordType1 ? 'Hide Password' : 'Show Password'" toggle="#password-field"
                            class="bx bx-hide field-icon toggle-password" [ngClass]="{
                            'bx-bx-hide': !passwordType1,
                            'bx-show': passwordType1
                          }" (click)="toggleFieldTextType1()"></span>
                        <div *ngIf="(submitted || confirmPassword.touched) && confirmPassword.invalid"
                            class="has-error">
                            {{showErrorService.showError('confirmPassword', confirmPassword.errors)}}
                        </div>
                        <div class="has-error"
                            *ngIf="((submitted || confirmPassword.touched || confirmPassword.dirty) && confirmPassword.valid && (user.confirmPassword != user.password))">
                            New Password & Confirm Password should be same
                        </div>
                    </div>
                    <div class="login-btn">
                        <button class="btn btn-primary btn-block" type="submit">Reset Password</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>