import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

// environment
import { environment } from 'src/environments/environment';

// plugins
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Services
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';

// interfaces
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { CommonModalComponent } from '../modals/common-modal/common-modal.component';


@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],

})
export class ResponsiveDataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterForm', { static: false }) filterForm;
  callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public uploadImageUrl = environment.uploadImgUrl;
  dtOptions: any = {};
  //  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdown: boolean = false;
  APIparameters: any = {};
  options: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public searchText: any;
  public color: any = '';
  public filterColor: any = '#4184EF';
  showSkeletonLoader1: boolean = true;
  // @Input() accessPermission: any;
  @Input() columns: Array<any>;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  @ViewChild('customPagination', { static: false }) customPagination: any;
  public downloadColumnsArr: any = [];
  public columnVisibilityOptions1: any;
  public columnVisibilityOptions2: boolean = false;
  public columnKey: any;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleDownloadButton: boolean;
  toggleFilterButton: boolean = false;
  getDowloadTemplates: string;
  downloadTemplatesArr: any = [];
  downloadUserFile: string;
  link: string;
  cols: any = [];
  configs: any = {};
  public colSettingCols: Array<any> = [];
  calenderData: any = {};
  status: boolean = false;
  statusDownload: boolean = false;
  statusColorPicker: boolean = false;
  statusFilterColorPicker: boolean = false;

  colors = [
    {
      hex: '#00cfdd',
      name: 'blue'
    },
    {
      hex: '#b3c0ce',
      name: 'gray'
    },
    {
      hex: '#FF5B5C',
      name: 'rose'
    },
    {
      hex: '#b87ee5',
      name: 'purple'
    },
    {
      hex: '#39DA8A',
      name: 'green'
    },
    {
      hex: '#FDAC41',
      name: 'yellow'
    },
  ];

  selectedColor: '';

  // Action
  public actions: any = [{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }];

  selectColor(hash, col) {
    this.color = col;
    document.getElementById("downloadDropdownColor").style.backgroundColor = hash;
    this.openCloseColorDropdown();
  }

  selectFilterColor(hash, col) {
    this.filterColor = col;
    document.getElementById("filterDropdownColor").style.backgroundColor = hash;
    this.openCloseColorFilterDropdown();

  }

  active: boolean = false;

  selectedColorName: '';

  selector() {
    if (!this.selectedColor) {
      return 'Color';
    }
    else {
      return '<span style="background: ' + this.selectedColor + '"></span> ' + this.selectedColorName;
    }
  }

  setColor(color, colorName) {
    this.selectedColor = color;
    this.selectedColorName = colorName;
    this.active = false;
  }

  toggleDropdown() {
    this.active = !this.active;

  }

  // open filter button
  openFilter() {
    this.status = !this.status;
  }

  // open download button
  openDownloadButton() {
    this.statusDownload = !this.statusDownload;
  }

  // open color dropdown
  openCloseColorDropdown() {
    this.statusColorPicker = !this.statusColorPicker;
  }

  // open color filter dropdown
  openColorDropdownFilter() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }

  // open color filter dropdown
  openCloseColorFilterDropdown() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }

  public savedFilters: any = [];
  public condition: any = '$and';
  public filteredArr: any = [];
  public submitted1: boolean = false;
  public filterId: any;
  public filterCols: any = [];
  public counter: number = 0;
  BlogDetails: string;

  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date()

  // Status Valued
  public status_items: any = [{ name: 'Active', value: 'Active' }, { name: 'Inactive', value: 'Inactive' }];

  constructor(public commonService: CommonService, public showErrorService: ShowErrorService,
    private router: Router, public bsModalRef: BsModalRef, private modalService: BsModalService, public restrictKeyPressService: RestrictKeyPressService, public zone: NgZone) {
    this.APIparameters = { page: 1, per_page: 10 };

    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];

  }

  ngOnInit() {
    this.addTask();
    // this.filteredArr[0].method = 'exclude';
    // this.tableConfig = this.tableSetupData;
    // this.configs = this.tableSetupData;

    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;

    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.colSettingCols.push(element)
      }
      // console.log(this.tableConfig.cols);

    });
    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/
    //if (this.accessPermission.statusUpdate && this.accessPermission.delete) {
    this.statusList = ['Active', 'Inactive'];
    if (this.conditions.showApplyDelete) {
      this.statusList.push('Delete');
    }
    // }
    // else if (this.accessPermission.statusUpdate || (this.accessPermission.statusUpdate && !this.accessPermission.delete)) {
    //   this.statusList = ['Active', 'Inactive'];
    //  } 
    //else if (!this.accessPermission.statusUpdate && this.accessPermission.delete) {
    //  this.statusList = ['Delete'];
    // } 
    //else {
    // this.conditions.showApplyStatus = false;
    const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
    // if (this.tableConfig.cols[index]) { this.tableConfig.cols[index].isVisible = true; }
    // }
    /*************************************************************/
    this.callApiToGetData();

  }
  addTask() {
    this.filteredArr.push({
      condition: this.condition
    })
  }

  ngAfterViewInit() {
    // this.callApiToGetData();
    // options for datatable
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: string;
  public httpMethodName: string;
  public getFilterlist: string;
  public DeleteUser: string;
  public ChangeStatus: string;
  public ResendPassword: string;

  async callApiToGetData() {
    if (this.type) {
      if (this.type === 'userList') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
        this.ResendPassword = this.callAPIConstants.ResendPassword + 'users/';
      } else if (this.type === 'userOperatorlist') {
        this.getMethodName = this.callAPIConstants.UserOperatorList;
        this.httpMethodName = 'post';
        this.APIparameters.id = this.tableSetupData.innerTableId;
      } else if (this.type === 'operatorList') {
        this.getMethodName = this.callAPIConstants.OperatorList;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.OperatorChangeStatus;
        this.DeleteUser = this.callAPIConstants.GetOperatorById;
        this.ResendPassword = this.callAPIConstants.ResendPassword + 'operators/';
      } else if (this.type === 'emailTemplateList') {
        this.getMethodName = this.callAPIConstants.EmailTemplateList;
        this.httpMethodName = 'post';
        this.getFilterlist = this.callAPIConstants.EmailColumnData;
        this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
        this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
      } else if (this.type === 'courseList') {
        this.getMethodName = this.callAPIConstants.GetCourseList;
        this.httpMethodName = 'post';
        this.DeleteUser = this.callAPIConstants.GetCourseById;
        this.ChangeStatus = this.callAPIConstants.ChangeCourseStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RolesList;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.DeleteUser = this.callAPIConstants.DeleteRole;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'teamMemeberList') {
        this.getMethodName = this.callAPIConstants.TeamMembers;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.TeamChangeStatus;
        this.DeleteUser = this.callAPIConstants.GetTeamMember;
        this.ResendPassword = this.callAPIConstants.TeamResendPassword;
      } else if (this.type === 'courseRequests') {
        this.getMethodName = this.callAPIConstants.GetCourseRequests;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.ChangeCourseRequestStatus;
      }

      else if (this.type === 'cmslist') {
        // this.getMethodName = this.callAPIConstants.cmsList;
        this.httpMethodName = 'post';
        // this.DeleteUser = this.callAPIConstants.cmsDelete;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'countriesListing') {
        this.getMethodName = this.callAPIConstants.CountriesListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.changeCountriesStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteCountries;
      } else if (this.type === 'currencyListing') {
        this.getMethodName = this.callAPIConstants.currenciesListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.changeCurrenciesStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteCurrencies;
      } else if (this.type === 'timezoneListing') {
        this.getMethodName = this.callAPIConstants.timezoneListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.changeTimezoneStatus;
        // this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteTimezones;
      } else if (this.type == 'faqsListing') {
        this.getMethodName = this.callAPIConstants.FaqsListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.FaqsStatusChange;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.DeleteFaqs;
      } else if (this.type == 'faqsCategoryListing') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.FaqsCategoryListing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.FaqsCategoryStatus;
        this.DeleteUser = this.callAPIConstants.DeleteFaqsCategory;
      } else if (this.type == 'StaticPageList') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.Listing;
        this.httpMethodName = 'post';
        this.ChangeStatus = this.callAPIConstants.ChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteBlog;
        this.BlogDetails = this.callAPIConstants.GetBlogDetails
      } else if (this.type == 'blogCategoryList') {
        this.ChangeStatus = this.callAPIConstants.BlogCategoryStatusChange
        this.httpMethodName = 'post';
        this.DeleteUser = this.callAPIConstants.DeleteBlogCategory
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.BlogCategoryListing;
      }
    }

    if (this.getMethodName) {
      await this.commonService.callApi(this.getMethodName, this.APIparameters, this.httpMethodName, false, false, false).then((success) => {
        if (!success.errors) {
          this.showSkeletonLoader1 = false;
          this.tableData.data = success.data;
          if (this.tableData.data && (this.type == "roleList" || this.type == "teamMemeberList" || this.type == 'courseList' || this.type === 'userList' || this.type === 'operatorList')) {
            this.tableData.data.map(e => {
              if (e.status === 'Inactive') {
                e.status = false;
              } else if (e.status === 'Active') {
                e.status = true;
              }
            });
          }
          if (this.type === 'courseRequests') {
            this.tableData.data.map(e => {
              e.status === 'Pending' ? e.status = { title: 'PENDING', value: 'Pending' } : (e.status === 'Inprogress' ? e.status = { title: 'IN PROGESS', value: 'Inprogress' } : (e.status === 'Completed' ? e.status = { title: 'COMPLETED', value: 'Completed' } : {}));
            });
          }

          if (success.data.latestColumns && success.data.latestColumns.length) {
            this.colSettingCols = [];
            let arr = [];
            success.data.latestColumns.forEach((e, lindex) => {
              if (e.drag) {
                this.colSettingCols.push(e);
              }
              if (e.filter) {
                this.filterCols.push(e);
              }
              this.tableSetupData.cols.forEach((c, colIndex) => {
                if (c.colName === e.colName) {
                  arr = this.arraymove(this.tableSetupData.cols, colIndex, lindex);
                } else if (c.colName === 'Select') {
                  this.tableSetupData.cols.splice(colIndex, 1)
                }
              })
            });
            this.tableSetupData.cols = arr;
            setTimeout(() => {
              this.tableData.data = [];
              this.tableConfig.cols = [];
              this.tableSetupData.cols.unshift({ type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true },)
              this.tableConfig.cols = this.tableSetupData.cols
              this.colSettingCols = this.colSettingCols
              this.tableData.data = success.data.listing;
              this.tableConfig.cols.forEach(async (element) => {
                if (success.data.columnSettings && this.type !== 'adminUserlist1') {
                  success.data.columnSettings.forEach((dataApi) => {
                    if (element.colFieldname === dataApi.key) {
                      element.isVisible = dataApi.status;
                    }
                  });
                }
                else {
                  if (this.columns && this.columns.length) {
                    this.columns.forEach((dataApi) => {
                      if (element.colFieldname === dataApi.key) {
                        element.isVisible = dataApi.status;
                      }
                    });
                  }
                }
              });
              // if (!this.accessPermission.statusUpdate) {
              const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
              if (this.tableConfig.cols[index]) {
                this.tableConfig.cols[index].isVisible = false;
                this.tableConfig.cols[index].filter = false;
                if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
              }
              // }
            }, 0);
          }
          else {
            // this.getLatestData(success);
          }
          this.total = success.meta.total;
          this.savedFilters = success.data.filterSettings;
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
    }
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  getLatestData(success) {
    // this.tableConfig = this.tableSetupData;
    // this.configs = this.tableSetupData;
    // this.cols = [];
    // this.filterCols = [];
    // this.tableConfig.cols.forEach(element => {
    //   if (element.drag) {
    //     this.cols.push(element)
    //   }
    //   if (element.filter) {
    //     this.filterCols.push(element)
    //   }
    // });
    // // console.log(this.filterCols, 'filterCols');

    // this.tableConfig.cols.forEach(async (element) => {
    //   if (success.data.columnSettings && this.type !== 'adminUserlist1') {
    //     success.data.columnSettings.forEach((dataApi) => {
    //       if (element.colFieldname === dataApi.key) {
    //         element.isVisible = dataApi.status;
    //       }
    //     });
    //   }
    //   else {
    //     if (this.columns && this.columns.length) {
    //       this.columns.forEach((dataApi) => {
    //         if (element.colFieldname === dataApi.key) {
    //           element.isVisible = dataApi.status;
    //         }
    //       });
    //     }

    //   }
    // });
    //if (!this.accessPermission.statusUpdate) {
    // const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
    // if (this.tableConfig.cols[index]) {
    //   this.tableConfig.cols[index].isVisible = false;
    //   this.tableConfig.cols[index].filter = false;
    //   if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
    // }
    //}
    // this.tableData.data = success.data.listing;
    this.rerenderDataTable();

  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV/Excel/pdf file of datatable( with and without filter )
  /********************************************************************************/
  public count: any;
  public errorMsg = "";
  selectColumns(tableConfigCols) {
    this.count = tableConfigCols.some(ele => {
      if (ele.isSelected) {
        return true;
      }
    })
    if (!this.count) {
      delete this.options.templateName;
    }
    // else {
    // }
  }
  toggleDownload() {
    this.toggleDownloadButton = !this.toggleDownloadButton;
    this.downloadColumnsArr = [];
    this.tableConfig.cols.forEach(ele => {
      ele.isSelected = false;
    })
    delete this.options.templateName;
    this.count = false;
    if (this.toggleDownloadButton) {
      this.CallAPIgetDownloadTemplates();
    }
  }
  saveDownloadTemplate(tableConfigCols) {
    this.submitted = true;
    this.downloadColumnsArr = [];
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.downloadColumnsArr.push({ key: element.colFieldname, status: element.isSelected });
      }
    });
    const data: any = { columns: this.downloadColumnsArr, color: this.color, description: this.options.templateName };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
    }
    if (this.options._id) {
      data.templateId = this.options._id;
    }
    if (this.options.templateName) {
      this.commonService.callApi(this.downloadColumns, data, 'post').then((success) => {
        if (success.status === 1) {
          this.CallAPIgetDownloadTemplates();
          this.toggleDownload();
          this.showErrorService.popToast('success', 'template saved successfully');
          this.callApiToGetData();
        }
        else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
    else {
      this.showErrorService.popToast('error', 'please enter template Name');
    }
  }
  showTemplate(i) {
    // this.modalService.show(TemplateDetailsComponent, { initialState: { columns: i.columns, type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' });
  }

  exportTemplate() {
    let templateData: any = { exportFields: [] };
    templateData.isExport = 1;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility && element.isVisible) {
        templateData.exportFields.push(element.colFieldname);
      }
    });
    if (templateData.exportFields.length > 1) {
      this.commonService.callApi(this.getMethodName, templateData, 'post', false, false).then(success => {
        if (!success.errors) {
          const blob = new Blob([success.text], { type: 'text/csv' });
          let fileName: any;
          switch (this.type) {
            case 'teamMemeberList':
              fileName = 'Teammates-';
              break;
            case 'courseList':
              fileName = 'Training courses-';
              break;
            case 'userList':
              fileName = 'Users-';
              break;
            case 'operatorList':
              fileName = 'Operators-';
              break;

            default:
              fileName = 'download-list-';
              break;
          }
          saveAs(blob, fileName + new Date().getTime() + '.csv');
          this.commonService.showSkeletonLoader = false;
        } else {
          this.showErrorService.popToast('error', 'Error downloading the file.');
        }
      });
    }
  }

  editTemplate(i) {
    // console.log(i);
    this.options = i;
    this.options.templateName = i.description;
    this.color = i.color;
    var hash = this.colors.find(a => a.name == i.color).hex;
    document.getElementById("downloadDropdownColor").style.backgroundColor = hash;
    this.count = true;
    this.tableConfig.cols.forEach(ele => {
      i.columns.forEach(ele1 => {
        if (ele1.status && ele1.key == ele.colFieldname) {
          ele.isSelected = true;
        }
        // else {
        //   ele.isSelected = false;
        // }
      })
    })
  }
  methodChange(i, key) {
    i.method = key;
  }

  removeTask(index) {
    this.filteredArr.splice(index, 1);
  }

  conditionChange(key) {
    this.condition = key;
  }

  applyLatestFilter(form?, item?) {
    this.submitted = true;
    if (form) {
      // this.filteredArr[0].push({
      //   condition:this.condition
      // })
      this.APIparameters = {
        page: 1,
        per_page: 10,
        columnKey: this.APIparameters.columnKey,
        filter: this.filteredArr,
        //condition: this.condition
      }
      this.clearFilter();
      // this.callApiToGetData();
    } else {
      this.APIparameters = {
        page: 1,
        per_page: 10,
        columnKey: this.APIparameters.columnKey,
        filter: item.filter,
        condition: item.condition
      }
      this.clearFilter();
      this.callApiToGetData();
    }
    this.openFilter();

  }

  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData = { status: '' };
  public statusList: any[];

  selectMultipleUser(data) {

    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data.id);
        if (x === -1) {
          this.selectedUserList.push(data.id);
        }
      } else {
        this.selectedUserList.push(data.id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data.id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }

  submitStatusForm(statusForm, statusFormData) {
    this.submitted = true;
    if (this.selectedUserList.length) {
      // DELETE
      console.log(this.selectedUserList, 'this.selectedUserList');

      if (statusFormData.status === 'Delete') {
        this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete');
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      } else if (statusFormData.status === 'Inactive' || statusFormData.status === 'Active') {
        let data;
        statusFormData.status === 'Inactive' ?
          data = { [this.params.statusParams]: this.selectedUserList, status: 'Inactive' } :
          data = { [this.params.statusParams]: this.selectedUserList, status: 'Active' };
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
        const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
      }
    } else {
      this.showErrorService.popToast('error', 'Select Users To Modify');
    }
    // statusForm.reset();
  }
  // *************************************************************//

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [5, 10, 25, 100];
  itemsPerPage = 10;

  pagination(e) {
    this.deselectMultipleUser();
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
    // setTimeout(() => {
    //   console.log('selectedUserList', this.selectedUserList);
    //   if (this.selectedUserList.length) {
    //     this.selectedUserList.forEach((element) => {
    //       const x = this.tableData.data.findIndex((o) => element === o._id);
    //       if (x > -1) {
    //         this.tableData.data[x].checkbox = true;
    //       }
    //     });
    //   }
    // }, 500);
  }

  selectItemPerPage(e) {
    this.customPagination._page = 1;
    this.APIparameters.per_page = e;
    this.APIparameters.page = 1;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
    this.deselectMultipleUser();
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }

  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility === true && element.isVisible === true) { count++; }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex((o) => o.isVisible === true && o.columnVisibility === true);
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data, methodName) {
    this.commonService.callApi(callAPI, data, methodName, false, false, false).then((success) => {
      if (!success.errors) {
        this.showErrorService.popToast('success', success.extra_meta.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
        this.selectedUserList = [];
      } else {
        this.showErrorService.popToast('error', success.errors.message);
      }
    });
  }
  // *************************************************************//

  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } else { this.dtTrigger.next(); }
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    if (this.type === 'emailTemplateList') {
      event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
      let data = {
        sort_by: { [event.sortColumn.colFieldname]: event.sortDirection }
      }
      this.APIparameters.sort_by = JSON.stringify(data.sort_by);
    } else {
      this.APIparameters.sort_by = event.sortColumn.colFieldname;
      this.APIparameters.order_by = event.sortDirection;
    }
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/

  performAction(k, i) {
    switch (k.id) {
      // VIEW action
      case 1:
        let detailPageUrl = '';
        switch (this.type) {
          case 'userList':
            detailPageUrl = this.URLConstants.USER_DETAIL;
            break;
          case 'operatorList':
            detailPageUrl = this.URLConstants.OPERATOR_DETAIL;
            break;
          case 'userOperatorlist':
            detailPageUrl = this.URLConstants.OPERATOR_DETAIL;
            break;
          case 'contentProviderList':
            detailPageUrl = this.URLConstants.CONTENT_PROVIDERS_DETAIL;
            break;

          default:
            break;
        }
        this.router.navigate([detailPageUrl, i.id]);
        break;
      // EDIT action
      case 2:
        let editPageUrl = '';
        switch (this.type) {
          case 'emailTemplateList':
            editPageUrl = this.URLConstants.EDIT_EMAIL_TEMPLATE;
            break;
          case 'roleList':
            editPageUrl = this.URLConstants.EDIT_ROLE;
            break;
          case 'teamMemeberList':
            editPageUrl = this.URLConstants.EDIT_TEAM_MEMBER;
            break;
          case 'operatorList':
            editPageUrl = this.URLConstants.EDIT_OPERATOR;
            break;
          case 'contentProviderList':
            editPageUrl = this.URLConstants.EDIT_CONTENT_PROVIDERS;
            break;
          case 'countriesListing':
            this.modalService.show(CommonModalComponent, { initialState: { type: this.type, id: i._id }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' });
            break;

          default:
            break;
        }
        this.router.navigate([editPageUrl, i.id]);

        break;
      // DELETE action
      case 3:
        this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
          if (result.value === true) {
            const dataObj = {
              [this.params.deleteParams]: [i.id],
            };
            let methodType = 'post';
            if (this.type === 'teamMemeberList' || this.type === 'operatorList') {
              this.DeleteUser = this.DeleteUser + i.id;
              methodType = 'delete';
            }
            this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj, methodType);
          }
        });
        break;
      // RESEND PASSWORD action
      case 4:
        this.commonService.callApi(this.ResendPassword + i.id, '', 'get', false, false).then((success) => {
          if (!success.errors) {
            this.showErrorService.popToast('success', success.extra_meta.message);
          } else {
            this.showErrorService.popToast('error', success.errors.message);
          }
        });
        break;

      default:
        break;
    }
  }
  // SWITCH action
  changeStatus(d) {
    const dataObj = {
      [this.params.statusParams]: [d.id],
      status: d.status ? 'Inactive' : 'Active'
    };
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj, 'post');
  }

  /***************************************************************
    @Purpose :  Drag & Drop columns
  /***************************************************************/
  drop(event: CdkDragDrop<string[]>) {
    // console.log(this.colSettingCols);
    moveItemInArray(this.colSettingCols, event.previousIndex, event.currentIndex);
  }

  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element.id);
          if (x === -1) {
            this.selectedUserList.push(element.id);
          }
        } else {
          this.selectedUserList.push(element.id);
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element.id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }


  /***************************************************************/

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterlist: any[] = [];
  public filters = {};
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }

  getSatutsFilterlistData(colName) {
    (colName === 'status' && this.type !== 'courseRequests') ? this.statusFilterlist = ['Active', 'Inactive'] : this.statusFilterlist = [{ title: 'PENDING', value: 'Pending' }, { title: 'COMPLETED', value: 'Completed' }, { title: 'IN PROGESS', value: 'Inprogress' }];
  }

  changeStatusFromList(e, obj) {
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, { ids: [obj.id], status: e.value }, 'post');


  }
  applyFilter() {
    this.filters = {};
    this.tableConfig.cols.forEach(e => {
      if (e.filter && e.value) {
        if (e.isDate || e.isDateTime) {
          this.filters[e.colFieldname] = new DatePipe('en-US').transform(e.value, 'yyyy-MM-dd');
        } else {
          this.filters[e.colFieldname] = e.value;
        }
      }
    });
    if (Object.keys(this.filters).length !== 0) {
      this.disableResetFilter = false;
      this.APIparameters.filters = this.filters;
      this.callApiToGetData();
    } else {
      this.showErrorService.popToast('error', 'Please fill at least one field to filter')
    }
  }

  resetFilter() {
    this.disableResetFilter = true;
    this.filters = {};
    this.tableConfig.cols.forEach((element) => {
      element.value = '';
    });
    this.APIparameters.filters = {};
    this.callApiToGetData();
  }
  /***************************************************************/

  CallAPIgetDownloadTemplates() {
    if (this.getDowloadTemplates) {
      this.commonService.callApi(`${this.getDowloadTemplates}/${this.tableSetupData.saveColumnKey}`, '', 'get', false, false).then((success) => {
        if (success.status === 1) {
          this.downloadTemplatesArr = success.data;
        }
      });
    }
  }

  dateCreated(fieldName, $event) {
    // if ($event != undefined && $event.length) {
    //     let startDate = ('0' + ($event[0].getMonth() + 1)).slice(-2) + '/' + ('0' + $event[0].getDate()).slice(-2) + '/' + $event[0].getFullYear();
    //     let endDate = ('0' + ($event[1].getMonth() + 1)).slice(-2) + '/' + ('0' + $event[1].getDate()).slice(-2) + '/' + $event[1].getFullYear();
    //     let dateFilterData = { 'startDate': startDate, 'endDate': endDate }
    //     const check_index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    //     if (check_index > -1) { this.filter[check_index][fieldName] = dateFilterData; } else {
    //       this.filter.push({ [fieldName]: dateFilterData });
    //     }
    //     this.disableApplyFilter = false;
    //     this.disableResetFilter = false;
    // }
  }
  /****************************************************************************
  // To get search list
  /****************************************************************************/
  searchData(event) {
    this.APIparameters.page = 1;
    if (event.target && event.target.value) {
      this.APIparameters.search_key = event.target.value;
    } else {
      delete this.APIparameters.search_key;
    }
    this.callApiToGetData();
  }
  /***************************************************************/
  /***************************************************************
  @Purpose : To Get Dynamic searchValues.
  /***************************************************************/
  searchValue(data) {
    alert(data);
    this.APIparameters.search_key = data;
    this.callApiToGetData();
  }

  // For performing selected action on the list
  actionSelected(action) {
    // DELETE
    if (action === 'Delete') {
      this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
        if (result.value === true) {
          const data = {
            userIds: this.selectedUserList
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete');
        } else {
          this.deselectMultipleUser();
        }
      });
    }
    // DELETE
    // STATUS
    if (action === 'Inactive' || action === 'Active') {
      let data;
      action === 'Inactive' ?
        data = { userIds: this.selectedUserList, status: false } :
        data = { userIds: this.selectedUserList, status: true };
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'post');
      this.deselectMultipleUser();
    }
    // STATUS
  }

  // To get the selected dateType info
  dateSelected(e, index) {
    // console.log(e, 'e');

    if (e.name !== 'custom') {
      // console.log(e, 'e');
      var date = new Date();
      let presentDate = (e.name === 'Previous Month' || e.name === 'Previous Quarter' || e.name === 'Previous Year') ? 'month/01/year' : date.toLocaleDateString();
      let value = {
        calendarSpecificCount: e.value,
        calendarSpecificType: e.type,
        presentDate: presentDate
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  // when getting startDate and endDate
  rangeSelected(e, index) {
    if (e) {
      let value = {
        startDate: e[0].toLocaleDateString(),
        endDate: e[1].toLocaleDateString()
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  // To clear the filter
  clearFilter() {
    this.filteredArr = [];
    this.addTask();
    this.filterColor = '#4184EF'
    this.callApiToGetData();
    this.filterForm.reset();
    this.submitted = false;
    this.submitted1 = false;
  }

  typeArrChanges(item) {
    delete (item.selectedDate);
    delete (item.value);
  }

  editFilter(e) {
    // console.log(e, 'e');

    this.filteredArr = e.filter;
    this.options.description1 = e.description;
    this.filterColor = e.color;
    var hash = this.colors.find(a => a.name == e.color).hex;
    document.getElementById("filterDropdownColor").style.backgroundColor = hash;
    this.condition = e.condition;
    this.filterId = e._id;
  }

  removeSearch() {
    delete this.searchText;
    delete this.APIparameters.search_key;
    // setTimeout(() => {
    this.callApiToGetData();
    // });
  }
}
