import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

//pipe
import { DateFilterPipe, limitTextPipe } from '../common/service/common-pipe.pipe';

// plugin
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';
//import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { CountdownModule } from 'ngx-countdown';
import { NgxSpinnerModule } from "ngx-spinner";
import { QRCodeModule } from 'angularx-qrcode';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LineTruncationLibModule, LineTruncationDirective } from 'ngx-line-truncation';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RatingModule } from 'ngx-bootstrap/rating';
import { HighchartsChartModule } from 'highcharts-angular';

// Component
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { CommonModalComponent } from './components/modals/common-modal/common-modal.component';
import { BnDatatableModule } from '../common/service/bn-datatable/bn-datatable.module';
import { AddblogComponent } from './components/modals/addblog/addblog.component';
import { FaqsListCategoryComponent } from './components/modals/faqs-list-category/faqs-list-category.component';
import { FaqsCategoryComponent } from './components/modals/faqs-category/faqs-category.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { FilterTypeAheadComponent } from './components/filter-type-ahead/filter-type-ahead.component';
import { NgxView360Module } from 'ngx-view360';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  imports: [
    AngularEditorModule,
    CKEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    CountdownModule,
    BnDatatableModule,
    DataTablesModule,
    NgxSpinnerModule,
    QRCodeModule,
    NgxFileDropModule,
    LineTruncationLibModule,
    SlickCarouselModule,
    NgxView360Module,
    HighchartsChartModule,
    TabsModule.forRoot(),
    RatingModule.forRoot(),
    //OwlDateTimeModule,
    //OwlNativeDateTimeModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    CommonModalComponent,
    AddblogComponent,
    FaqsListCategoryComponent,
    FaqsCategoryComponent,
    SubHeaderComponent,
    FilterTypeAheadComponent,    
    //pipe
    limitTextPipe,
    DateFilterPipe,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    //components
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    DragDropModule,
    SubHeaderComponent,    

    //modules
    FormsModule,
    CommonModule,
    BnDatatableModule,

    //pipe
    limitTextPipe,
    DateFilterPipe,

    //plugin
    AngularEditorModule,
    CKEditorModule,
    ImageCropperModule,
    //OwlDateTimeModule,
    //OwlNativeDateTimeModule,
    AccordionModule,
    BsDatepickerModule,
    TimepickerModule,
    TabsModule,
    QRCodeModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    CountdownModule,
    NgxSpinnerModule,
    NgxFileDropModule,
    ProgressbarModule,
    LineTruncationDirective,
    NgxView360Module,
    SlickCarouselModule,
    RatingModule,
    HighchartsChartModule,
  ],
  entryComponents: [CommonModalComponent],
})
export class SharedModule { }
