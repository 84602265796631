import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: []
})
export class ResetPasswordComponent implements OnInit {
  // Variable declarations
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen: boolean = false;
  fieldTextType: boolean;
  passwordType: boolean;
  passwordType1: boolean;

  public user: any = {};
  public submitted: boolean = false;

  constructor(private router: Router, private commonService: CommonService, public showErrorService: ShowErrorService) { }

  ngOnInit(): void {

  }

  /*************************************************************
  @PURPOSE      : Reset Password
  @PARAMETERS   : form, Obj
  @RETURN       : NA
  /*************************************************************/
  resetPassword(form, user) {
    this.submitted = true;
    if (form.valid) {
      let resetdata = {
        email: this.commonService.decrypt(window.sessionStorage.getItem('email')),
        token: this.commonService.decrypt(window.sessionStorage.getItem('token')),
        password: user.password,
        password_confirmation: user.confirmPassword
      }
      this.commonService.callApi(this.callAPIConstants.ResetPassword, resetdata, 'post', true, false).then((success) => {
        if (!success.errors) {
          window.sessionStorage.removeItem('email');
          window.sessionStorage.removeItem('token');
          this.showErrorService.popToast('success', success.extra_meta.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }

  }

  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }
  toggleFieldTextType1() {
    this.passwordType1 = !this.passwordType1;
  }

}


