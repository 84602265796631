export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/password-reset',
    VERIFY_CODE: '/verify-code',
    RESET_PASS: '/reset-password',

    /***** Authorized Pages *****/
    MY_PROFILE: '/my-profile',
    MASTER: '/master',
    DASHBOARD: '/dashboard',


    /***** Manage Subadmins *****/
    TEAM_MEMBERS_LIST: '/manage-subadmins',
    ADD_TEAM_MEMBER: '/manage-subadmins/add-member',
    EDIT_TEAM_MEMBER: '/manage-subadmins/edit-member/',
    // VIEW_TEAM_MEMBER: '/manage-subadmins/view-member/',

    /***** Courses Module *****/
    COURSES_LIST: '/training-courses',
    COURSE_REQUESTS: 'training-course-requests',

    /***** Email Templates *****/
    EMAIL_TEMPLATE_list: '/email-template',
    EDIT_EMAIL_TEMPLATE: '/email-template/edit-details/',
    EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',

    /***** Manage Users *****/
    USERS: '/user-management/users',
    USER_DETAIL: '/user-management/users/view-details/',
    ADD_USER: 'user-management/users/add/',
    EDIT_USER: 'user-management/users/edit/',
    ASSIGNED_USERS: '/user-management/users/assigned-users',
    OPERATORS: '/user-management/operators',
    OPERATOR_DETAIL: '/user-management/operators/view-details/',
    ADD_OPERATOR: '/user-management/operators/add',
    EDIT_OPERATOR: '/user-management/operators/edit/',
    CONTENT_PROVIDERS: '/user-management/content-providers',
    CONTENT_PROVIDERS_DETAIL: '/user-management/content-providers/view-details/',
    ADD_CONTENT_PROVIDERS: '/user-management/content-providers/add',
    EDIT_CONTENT_PROVIDERS: '/user-management/content-providers/add',


    /***** Manage Groups *****/
    GROUP_DETAIL: '/groups/view/',
    CREATE_GROUP: '/groups/add',
    EDIT_GROUP: '/groups/edit/',

    /***** Manage Roles *****/
    MANAGE_ROLES: '/manage-roles',
    ADD_ROLE: '/manage-roles/add-role',
    EDIT_ROLE: '/manage-roles/edit-details/',



    /***** Analytics Module  *****/
    APP_USAGE: '/analytics/app-usage',
    LOGIN_STATISTICS: '/analytics/login-statistics',

    /***** Industry Module *****/
    INDUSTRIES: '/manage-industries',
    ADD_INDUSTRY: '/manage-industries/add-industry',
    EDIT_INDUSTRY: '/manage-industries/edit-details/',

    /***** Product Module *****/
    PRODUCT: '/product',

    /***** Resources Module *****/
    RESOURCES: '/resource',

    /***** Jobs Module *****/
    JOBS: '/jobs',

    /***** My Account Module *****/
    MY_ACCOUNT: '/my-account',
    CHANGE_PASS: '/my-account/change-password',


    /***** Setting Pages *****/
    SETTING_GENERAL: '/setting/setting-general',
    SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    SETTING_SMTPSMS: '/setting/setting-smtpsms',
    SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',


    /***** Push Notification Module *****/
    PUSH_NOTIFICATION_LIST: '/push-notification',

    /***** Manage Subscription Plan Module *****/
    MANAGE_SUBSCRIPTION_PLAN_LIST: '/manage-subscription',


    /***** Payout Module *****/
    UPCOMING_PAYOUTS: '/payout/upcoming-payouts',
    PAYOUT_HISTORY: '/payout/payout-history',
    ALL_TRANSACTIONS: "/payout/all-transactions",

    /***** Settings Module *****/
    SETTINGS: '/settings',

};
