import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URLConstants } from '../../../common/constants/routerLink-constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent implements OnInit {
  URLConstants = URLConstants;
  public subCoursesMenu: boolean = false;
  public subAnalyticsMenu: boolean = false;
  public subUserMenu: boolean = false;
  public subDirectoryMenu: boolean = false;
  public teamMenuFlag: boolean = false;
  // public subSettingsMenu: boolean = false;
  // public subBlogMenu: boolean = false;
  // public subBlogMenuList: boolean = false;

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  //closeSidebar() {
  // var  sidebar = document.getElementsByClassName('sidebar');
  // sidebar[0].style.display = 'none';
  // }
  // var sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
  // sidebar[0].style.display = 'none';
  //}



  openCloseSubMenu(subMenu) {
    //var blog = document.getElementById('blog');

    if (subMenu == 'subCoursesMenu') {
      this.subCoursesMenu = !this.subCoursesMenu;
      this.subUserMenu = this.subDirectoryMenu = false
      // } else if (subMenu == 'subUserMenu') {
      //   this.subUserMenu = !this.subUserMenu
      //   this.subCoursesMenu = this.subDirectoryMenu = false;
      // } else if (subMenu == 'subDirectoryMenu') {
      //   this.subDirectoryMenu = !this.subDirectoryMenu
      //   this.subCoursesMenu = this.subUserMenu = false;
    } else if (subMenu == 'subAnalyticsMenu') {
      this.subAnalyticsMenu = !this.subAnalyticsMenu;
      this.subCoursesMenu = this.subUserMenu = this.subDirectoryMenu = false
    } else if (subMenu == 'subUserMenu') {
      this.subUserMenu = !this.subUserMenu;
      this.subCoursesMenu = this.subAnalyticsMenu = this.subDirectoryMenu = false
    } else {
      this.subCoursesMenu = this.subUserMenu = this.subDirectoryMenu = false;
    }

    //  else if(subMenu== 'subContentMenu') {
    //   this.subContentMenu=!this.subContentMenu;
    //   this.subUserMenu = this.subSettingsMenu = this.subBlogMenu = false;
    // } else if(subMenu== 'subSettingsMenu') {
    //   this.subSettingsMenu=!this.subSettingsMenu;
    //   this.subContentMenu = this.subUserMenu = this.subBlogMenu = false;
    // } else if(subMenu == 'subBlogMenu') {
    //   this.subBlogMenu =!this.subBlogMenu;
    //   this.subContentMenu = this.subSettingsMenu = this.subUserMenu = false;
    // }else if(subMenu == 'subBlogMenuList') {
    //   this.subBlogMenuList=!this.subBlogMenuList;
    //   if(blog.style.display == 'none') {
    //     blog.style.display = 'block';
    //   } else {
    //     blog.style.display = 'none'
    //   }

    //  this.subContentMenu = this.subSettingsMenu = this.subUserMenu = false;
    // }
  }
  teamResultOpen() {
    this.teamMenuFlag = !this.teamMenuFlag;
  }

  changeStyle() {


  }

}





