<aside class="sidebar">
    <div class="sidebar-inner-wrapper">
        <div class="sidebar-menu">
            <ul class="sidebar-menu-list">
                <li class="menu-list-item has-child-submenu level1">
                    <a [routerLink]="[URLConstants.DASHBOARD]" class="menu-link"
                        [ngClass]="{'active': router.url.includes(URLConstants.DASHBOARD)}">
                        <em class="bx bx-bar-chart-square"></em>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>

                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.TEAM_MEMBERS_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.TEAM_MEMBERS_LIST)}">
                        <em class='bx bx-user-plus'></em>
                        <span class="menu-title">Manage Subadmins</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subAnalyticsMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subAnalyticsMenu')"
                        [ngClass]="{'active':subAnalyticsMenu || router.url.includes(URLConstants.APP_USAGE) || router.url.includes(URLConstants.LOGIN_STATISTICS)}">
                        <em class="bx bx-bar-chart-alt"></em>
                        <span class="menu-title">Analytics</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : subAnalyticsMenu}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.APP_USAGE]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.APP_USAGE)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">App usage</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.LOGIN_STATISTICS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.LOGIN_STATISTICS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Login Statistics</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.COURSES_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.COURSES_LIST)}">
                        <em class="bx bx-news"></em>
                        <span class="menu-title">Training Courses</span>
                    </a>
                </li>

                <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subUserMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subUserMenu')"
                        [ngClass]="{'active':subUserMenu || router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL) || router.url.includes(URLConstants.OPERATORS)  || router.url.includes(URLConstants.OPERATOR_DETAIL) || router.url.includes(URLConstants.CONTENT_PROVIDERS) || router.url.includes(URLConstants.CONTENT_PROVIDERS_DETAIL)}">
                        <em class="bx bx-group"></em>
                        <span class="menu-title">Manage Users</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : subUserMenu}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.USERS]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USER_DETAIL))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Users</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.OPERATORS]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.OPERATORS) || router.url.includes(URLConstants.OPERATOR_DETAIL))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Operators</span>
                            </a>
                        </li>
                        <!-- <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.CONTENT_PROVIDERS]" class="menu-link"
                                [ngClass]="{'active':(router.url.includes(URLConstants.CONTENT_PROVIDERS) || router.url.includes(URLConstants.CONTENT_PROVIDERS_DETAIL))}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Content Providers</span>
                            </a>
                        </li> -->
                    </ul>
                </li>
                <!-- <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.PRODUCT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.PRODUCT)}">
                        <em class="bx bx-cube-alt"></em>
                        <span class="menu-title">Products</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.RESOURCES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.RESOURCES)}">
                        <em class="bx bx-spreadsheet"></em>
                        <span class="menu-title">Resources</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.JOBS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.JOBS)}">
                        <em class="bx bx-briefcase-alt"></em>
                        <span class="menu-title">Jobs</span>
                    </a>
                </li> -->
                <!-- <li class="menu-list-item has-child-submenu level1" [ngClass]="{'submenu-open':subDirectoryMenu}">
                    <a class="menu-link" (click)="openCloseSubMenu('subDirectoryMenu')"
                        [ngClass]="{'active':subDirectoryMenu || router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-data"></em>
                        <span class="menu-title">Directories</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show':subDirectoryMenu}">
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Product Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Resource Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Course Directory</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-receipt"></em>
                        <span class="menu-title">News</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-happy-alt"></em>
                        <span class="menu-title">Feedback</span>
                    </a>
                </li>
                <li class="menu-list-item">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-chart"></em>
                        <span class="menu-title">Reports</span>
                    </a>
                </li> -->
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.COURSE_REQUESTS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.COURSE_REQUESTS)}">
                        <em class="bx bx-git-compare"></em>
                        <span class="menu-title">Training Course Requests</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('emailMenu')">
                    <a [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.EMAIL_TEMPLATE_list)}">
                        <em class='bx bx-envelope'></em>
                        <span class="menu-title">Email Management</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item" (click)="openCloseSubMenu('roleMenu')">
                    <a [routerLink]="[URLConstants.MANAGE_ROLES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MANAGE_ROLES)}">
                        <em class='bx bx-id-card'></em>
                        <span class="menu-title">Roles</span>
                    </a>
                </li> -->
                <li class="menu-list-item">
                    <a [routerLink]="[URLConstants.MY_ACCOUNT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MY_ACCOUNT)}">
                        <em class="bx bx-cog"></em>
                        <span class="menu-title">My Accounts</span>
                    </a>
                </li>
            </ul>
            <!-- <div class="upgrade-course">
                <div class="arrow-up"><em class="bx bx-up-arrow-alt"></em></div>
                <h5>Upgrade to Pro for more resources</h5>
                <a href="javascript:;" class="upgrade-btn">Upgrade</a>
            </div> -->
        </div>
    </div>
</aside>